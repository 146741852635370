/* Override the color for finished steps */
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: rgb(0, 188, 0) !important;
  /* Change the background color to green */
  border-color: rgb(0, 188, 0) !important;
  /* Change the border color to green */
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
  /* Ensure the checkmark icon color is white for visibility */
}

/* Change the color of the joining bars for finished steps */
.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: rgb(0, 188, 0) !important;
  height: 4px !important;
  /* Adjust the height for thickness */
  /* width: 4px !important; */
  /* Adjust the width for thickness */
  /* Change the bar color to green */
}

/* Change the title text color of error step to black */
.ant-steps-item-error .ant-steps-item-title {
  color: black !important;
}

/* Increase the thickness of the joining bars for all steps */
.ant-steps-item-container > .ant-steps-item-tail::after {
  height: 4px !important;
  background-color: red !important;
  /* Adjust the height for thickness */
  /* width: 4px !important;  */
}

.pdd-input-style {
  font-size: 18px;
  font-weight: 600;
  color: black;
  border-radius: 150px;
  margin-right: 18px;
  flex: 1;
}

/* Ensure this is included in your CSS file */
.custom-placeholder::placeholder {
  color: #de1414 !important;
  /* Change color as needed */
  font-style: italic !important;
  /* Italicize the text */
  font-weight: 500 !important;
  /* Adjust weight */
  opacity: 1 !important;
  /* Ensure full opacity */
}

/* More specific selector */
.ant-input.custom-placeholder::placeholder {
  color: #de1414 !important;
  font-style: italic !important;
  font-weight: 500 !important;
  opacity: 1 !important;
}

.checklist-item {
  font-family: "Poppins", sans-serif;
  /* Ensure Poppins is applied */
  font-size: 20px;
  /* Adjust this value as needed */
  padding: 8px 12px;
  /* Consistent padding */
  transition: background-color 0.3s;
  /* Smooth background transition */
}

.checklist-item:hover {
  background-color: #ff0000;
  /* Hover effect */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
  /* Adjust duration as needed */
}

.selectAndsearch {
  display: flex;
  gap: 0px;
  margin-bottom: 1rem;
  /* margin-left: 0.3rem; */
}

@media screen and (max-width: 1000px) {
  .selectAndsearch {
    display: flex;
    gap: 20px;
  }
}

@media screen and (max-width: 500px) {
  .selectAndsearch {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 1rem;
  }
}

.mySearchBar {
  margin-left: 0;
  width: 290;
}

@media screen and (max-width: 576px) {
  .mySearchBar {
    margin-left: !important 1rem;
    width: 200px;
  }
}

@media screen and (max-width: 500px) {
  .mySearchBar {
    margin-left: !important 1rem;
    width: 280px;
  }
}

@media screen and (max-width: 400px) {
  .mySearchBar {
    margin-left: !important 1rem;
    width: 220px;
  }
}

.searchbarContainer {
  margin-left: 0px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .searchbarContainer {
    margin-left: 1rem;
  }
}

/* :where(.css-dev-only-do-not-override-xu9wm8).ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 8px;
  background-color: rgb(76, 145, 249);
} */

/* tyre */
.tire {
  animation: rotating 2s linear infinite;
  -webkit-animation: rotating 2s linear infinite; /* For Safari */
  transform-origin: center;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Tire */
.tire {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-left: 3px;
  margin-top: 2px;
  background-color: #333;
  -webkit-animation: rotating 2s linear infinite;
  transform-origin: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
