.myProfile {
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

@media screen and (max-width: 1400px) {
    .myProfile {
        border-radius: 50%;
        width: 110px;
        height: 110px;
        object-fit: cover;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
}

@media screen and (max-width: 1200px) {
    .myProfile {
        border-radius: 50%;
        width: 100px;
        height: 100px;
        object-fit: cover;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
}

.fullName {
    font-size: 30px;
    margin: 0;
    padding: 0;
    line-height: 1;
}

@media screen and (max-width: 1400px) {
    .fullName {
        font-size: 20px;
        margin: 0;
        padding: 0;
        line-height: 1;
    }
}

.regNumberA {
    font-size: 20px;
    font-size: italic;
    color: gray;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    margin-top: 10px;
}

@media screen and (max-width: 1400px) {
    .regNumberA {
        font-size: 15px;
        font-size: italic;
        color: gray;
        font-weight: 700;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-top: 10px;
    }
}

.refNumberA {
    font-size: 20px;
    font-size: italic;
    color: gray;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    margin-top: 10px;
}

@media screen and (max-width: 1400px) {
    .refNumberA {
        font-size: 15px;
        font-size: italic;
        color: gray;
        font-weight: 700;
        margin: 0;
        padding: 0;
        line-height: 1;
        margin-top: 10px;
    }
}

.resolvePara {
    font-size: 25px;
    color: gray;
}

@media screen and (max-width: 1400px) {
    .resolvePara {
        font-size: 20px;
        color: gray;
    }
}

.customerForm {
    margin-left: 10px;
    margin-right: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media screen and (max-width: 1400px) {
    .customerForm {
        margin-left: 10px;
        margin-right: 20px;
        margin-top: 0px;
        margin-bottom: 10px;
    }
}

.dispoPara {
    font-size: 25px;
    color: gray;
}

@media screen and (max-width: 1400px) {
    .dispoPara {
        font-size: 20px;
        color: gray;
    }
}

.quesDrop {
    color: gray;
    font-size: 20px;
}

@media screen and (max-width: 1400px) {
    .quesDrop {
        color: gray;
        font-size: 15px;
    }
}

.quesList {
    background-color: #d7f0e4;
    border-radius: 30px;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 18px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

@media screen and (max-width: 1400px) {
    .quesList {
        background-color: #d7f0e4;
        border-radius: 30px;
        padding: 15px;
        margin-bottom: 10px;
        font-size: 10px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }
}

.quesLabel {
    font-size: 20px;
    font-weight: 600;
}

@media screen and (max-width: 1400px) {
    .quesLabel {
        font-size: 17px;
        font-weight: 600;
    }
}

.enterPara {
    font-size: 20px;
    color: gray;
    font-family: 500px;
}

@media screen and (max-width: 1400px) {
    .enterPara {
        font-size: 17px;
        color: gray;
        font-family: 500px;
    }
}

.documentsPara {
    font-size: 25px;
    color: gray;
}

@media screen and (max-width: 1400px) {
    .documentsPara {
        font-size: 20px;
        color: gray;
    }
}

.customerDiv {
    display: flex;
    align-items: center;
    font-size: 25px;
    color: gray;
}

@media screen and (max-width: 1400px) {
    .customerDiv {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: gray;
    }
}

.customerHeads {
    font-size: 20px;
}

@media screen and (max-width: 1400px) {
    .customerHeads {
        font-size: 17px;
    }
}

.customerSubHead {
    font-size: 20px;
    font-weight: 700;
    color: gray;
}

@media screen and (max-width: 1400px) {
    .customerSubHead {
        font-size: 17px;
        font-weight: 700;
        color: gray;
    }
}

.steps-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}

.ant-steps-horizontal .ant-steps-item {
    max-width: 200px;

}



@media screen and (max-width: 1320px) {
    .ant-steps-horizontal .ant-steps-item {
        max-width: 100px;
    }
}

@media screen and (max-width: 1200px) {
    .ant-steps-horizontal .ant-steps-item {
        max-width: 90px;
    }
}


.chatCard {
    position: absolute;
    right: -3rem;
    top: -7rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    overflow-y: none;
}

.resolveCard {
    height: 75vh;
}

@media screen and (max-width:1500px) {
    .resolveCard {
        height: 68.5vh;
    }
}

@media screen and (max-width:1400px) {
    .resolveCard {
        height: 64vh;
    }
}



.feedbackCard {
    height: 42vh
}

@media screen and (max-width:1500px) {
    .feedbackCard {
        height: 39vh
    }
}

@media screen and (max-width:1400px) {
    .feedbackCard {
        height: 36vh
    }
}

@media screen and (min-width:1500px) {
    .feedbackCard {
        height: 42vh
    }
}

.customerCard {
    height: 68vh;
}

@media screen and (max-width:1500px) {
    .customerCard {
        height: 65vh
    }
}

@media screen and (max-width:1400px) {
    .customerCard {
        height: 68vh
    }
}

@media screen and (max-width:1300px) {
    .customerCard {
        height: 64vh
    }
}