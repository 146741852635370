/* Define styles for the container that wraps the slider */
.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
}

/* Define styles for the slider itself */
.slider {
  display: flex;
  background-color: #ccc;
  border-radius: 20px;
  overflow: hidden;
  width: 300px;
  height: 40px;
}

/* Define styles for the buttons inside the slider */
.slider-button-container {
  flex: 1;
  width: 50%; /* Set a width of 50% to make both buttons the same size */
}

.slider-button {
  display: block; /* Change to block-level element */
  text-align: center;
  padding: 8px;
  cursor: pointer;
  color: #333; /* Darker text color */
  transition: all 0.5s ease;
  border: none;
  outline: none;
  font-weight: bold;
  background-color: transparent;
  border-radius: 20px;
  width: 100%; /* Set a width of 100% to fill the button container */
  font-family: 'Arial', sans-serif; /* Specify a font family */
  font-size: 16px; /* Adjust font size */
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); Add a subtle text shadow */
}

/* Add this CSS for white text color when the button is selected */
.slider-button.active-button {
  color: white;
}

.daily {
  background-color: #bdc3c7;
  /* background: linear-gradient(45deg, #bdc3c7, #787d83) */
}

.weekly {
  background-color: #bdc3c7;
  /* background: linear-gradient(45deg, #bdc3c7, #787d83) */
}

.monthly {
  background-color: #bdc3c7;
  /* background: linear-gradient(45deg, #bdc3c7, #787d83) */
}

/* Define styles for the active button */
.active-button {
  background: linear-gradient(45deg, #ff512f, #dd2476);; /* Gradient background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4); /* Box shadow */
  transform: scale(1.1);
  transition: background 0.3s ease-in-out, transform 0.3s ease-in-out; /* Add transitions */
}

.active-button:hover {
  background: linear-gradient(45deg, #dd2476, #ff512f); /* Change gradient color on hover */
}
/* .active-button { 
  background-color: #1e90ff;
  transform: scale(1.1);
} */
