/* Override the color for finished steps */
.ant-steps-item-finish .ant-steps-item-icon {
    background-color: rgb(0, 188, 0) !important;
    /* Change the background color to green */
    border-color: rgb(0, 188, 0) !important;
    /* Change the border color to green */
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
    color: white !important;
    /* Ensure the checkmark icon color is white for visibility */
}

/* Change the color of the joining bars for finished steps */
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: rgb(0, 188, 0) !important;
    height: 4px !important;
    /* Adjust the height for thickness */
    /* width: 4px !important; */
    /* Adjust the width for thickness */
    /* Change the bar color to green */
}

/* Change the title text color of error step to black */
.ant-steps-item-error .ant-steps-item-title {
    color: black !important;
}

/* Increase the thickness of the joining bars for all steps */
.ant-steps-item-container>.ant-steps-item-tail::after {
    height: 4px !important;
    background-color: red !important;
    /* Adjust the height for thickness */
    /* width: 4px !important;  */
}